.row {
    gap: 0.25rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-self: flex-start;
}

.column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-self: flex-start;
    gap: 0.25rem;
}

.column div {
    white-space: pre;
}

.app-container {
    display: flex;
    flex-direction: column;
    padding: 0.7rem;
    font-family: Arial, sans-serif;
    margin: 0; /* Ensure no default margins interfere */
}

.contentWrapper {
    border: 0.0625rem solid #282929;
    border-radius: 0.3rem;
    padding: 0.4rem; 
    padding-top: 0.7rem;
}
  
.orderSection {
    position: relative;
    display: flex;
    flex-direction: row;
    border: 0.1875rem solid #a8b4b6;
    border-radius: 0.325rem;
    padding: 0.625rem;
    padding-top: 0.875rem;
    margin: 0.25rem;
    gap: 0.1rem;
    white-space: pre;
}

.orderSection h3 {
    position: absolute;
    margin: -0.75rem;
    margin-left: 0rem;
    top: 0.05rem;
    left: 0.4rem;
    padding: 0rem;
    padding-left: 0.175rem;
    padding-right: 0.175rem;
    background-color: #fff;
    font-size: 0.8rem;
}

.orderSection div {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 0.25rem;
    font-size: 0.825rem;
}

.orderSection div label {
    margin-right: 0.5rem;
    font-size: 0.85rem;
}

.orderSection div div {
    display: flex;
    flex-direction: row;
    gap: 0.2rem;
    flex: 1;
    flex-grow: 1;
    flex-direction: row;
}

.orderSection div div select,
.orderSection div div textarea,
.orderSection div div input {
    border-radius: 0.25rem;
    padding-inline: 0.2rem;
    padding: 0.07rem;
    display: flex;
    flex: 1;
    flex-grow: 1;
    border: 0.125rem solid black;
    font-size: 0.775rem;
    font-weight: 500;
    flex-direction: row;
    text-align: left;
    overflow: auto;
}

.orderSection div div div {
    text-align: left;
    justify-content: flex-start;
    justify-self: flex-start;
    margin-left: 0.35rem;
}

.selected {
    border: 0.0625rem solid black;
    border-bottom: none;
}

.scrollTable {
    border-collapse: collapse;
    justify-self: start;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    table-layout: auto;
}  

.scrollTable th,
.scrollTable td {
  border: 0.0625rem solid black;
  padding: 0.25rem;
  overflow: hidden;
  white-space: nowrap;
}

.tableContainer {
    max-height: 20rem;
    max-width: 72rem;
    overflow-x: auto;
    overflow-y: auto;
} 

.align {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.fileDrop {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 0.0625rem dashed #000;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 500;
    color: #000;
}

.fileDropText {
    font-size: 2rem;
    font-weight: 500;
    color: #000;
    text-align: center;
}

.iconButton {
    padding: 0.6rem;
    font-size: 0.7rem;
}

.orderSectionClose {
    position: absolute; 
    color: red; 
    top: -9px; 
    right: -7px; 
    padding: 0.1rem; 
    background-color: white; 
    z-index: 2;
}
