



.spinner {
    width: 5rem;
    height: 5rem;
    border: 0.75rem solid #f3f3f3;
    border-top: 0.75rem solid #3498db;
    border-radius: 50%;
    animation: spin 1.5s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


.loading-dots {
    display: flex;
    gap: 0.625rem;
}
  
.loading-dots div {
    width: 1rem;
    height: 1rem;
    background-color: #3498db;
    border-radius: 50%;
    animation: bounce 1.4s infinite ease-in-out;
}
  
.loading-dots div:nth-child(1) {
    animation-delay: -0.32s;
}
  
.loading-dots div:nth-child(2) {
    animation-delay: -0.16s;
}
  
@keyframes bounce {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
}