
.messageView {
    padding: 0.125rem; 
    border-radius: 0.25rem; 
    border: 0.0625rem solid black; 
    max-height: 90rem; 
    min-height: 15.625rem; 
    overflow-y: auto;
}

.messageList {
    display: flex; 
    flex: 1; 
    flex-Direction: column; 
    height: 100%; 
    overflow-Y: auto;
}

.noConversations {
    display: flex;
    flex: 1; 
    align-items: center; 
    justify-content: center;
}

.noConversationsText {
    font-size: 1rem;
    text-align: center;
} 

.List {
    list-style-type: none; 
    padding: 0.125rem 0.1875rem;
    margin: 0rem;
}

.conversationCard {
    display: flex;
    flex-Direction: row;
    padding: 0.15625rem;
    margin-Bottom: 0.15625rem;
    background-Color: #fff;
    border-Radius: 0.3125rem;
    cursor: pointer;
    align-Items: center;
    box-Shadow: 0rem 0.0625rem 0.125rem rgba(0,0,0,0.1);
    border: 0.0625rem solid #666;
}

.titleCol {
    display: flex;
    flex-Direction: column;
    margin-Left: 0.1875rem;
    flex: 1;
    flex-shrink: 1;
}

.title {
    font-Size: 0.9375rem;
    font-Weight: bold;
    color: #333;
    flex-shrink: 1;
}

.timestamp {
    font-Size: 0.78125rem;
    font-Weight: 600;
    color: #222;
}

.previewText {
    font-size: 0.875rem;
    color: #555;
    margin: 0rem;
    margin-right: 0.3125rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    flex-shrink: 1;
}