html, body, #root {
    height: 100vh;
    margin: 0;
    padding: 0;
    font-size: 15.75px;
}

.wmax {
    max-width: 82vmax;
    min-width: 0vmax;
}

.mainContainer {
    display: flex;
    flex-direction: column;
    padding-inline: 0.215rem; 
    height: 100vh;
}

.flex-container {
    display: flex;
    flex-direction: row;
    width: 80vh; 
}

.row {
    gap: 0.4rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-self: flex-start;
}

.spacedRow {
    gap: 0.4rem; 
    padding: 0.15rem; 
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.spacedRow button {
    padding: 0.2rem; 
    padding-inline: 0.4rem;
    border-radius: 0.2rem; 
    border: 0.05rem solid black;
    font-size: 0.8rem; 
}

.infoBox {
    display: inline-block;
    padding: 0.05rem; 
    padding-inline: 0.15rem;
    margin-left: 0.4rem; 
    font-size: 0.815rem; 
    border-radius: 0.15rem; 
    border: 0.1rem solid #0c6cfc; 
}

.selected {
    background-color: white;
    color: white;
    border: 0.05rem solid black; 
    border-bottom: none;
}

.invSelected {
    background-color: white;
    color: white;
    border: 0.05rem solid black; 
    border-top: none;
}

.stats {
    border-radius: 0.2rem; 
    border: 0.05rem solid #0c6cfc; 
    margin-left: 0.5rem; 
}

.topmenue {
    display: flex;
    flex-direction: row;
    gap: 0.25rem; 
}

.smallSection {
    border-radius: 0.2rem; 
    flex-direction: column;
    border: 0.05rem solid #111; 
}

.largeSection {
    border-radius: 0.2rem; 
    flex-direction: column;
    border: 0.05rem solid #111; 
}

.messageSection {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-shrink: 1;
    max-height: 7.25rem;
    padding: 0.125rem 0.4rem; 
    margin-left: 0.25rem; 
    border-radius: 0.2rem; 
    max-width: 15rem; 
    border: 0.05rem solid #111; 
    box-sizing: border-box;
}

.messageStat {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 0.05rem;
}

.statusText { 
    font-size: 0.875rem;
}

.vehicleSection {
    display: flex;
    flex-wrap: wrap;
    padding: 0.24rem;
    margin-right: 0.2rem;
    flex: 1;
    overflow-y: auto;
    min-height: 3rem;
    min-width: 10.5rem;
    width: max-content;
    height: max-content;
    max-width: calc(100% - 2 * 0.25rem - 0.2rem - 4px);
    border-radius: 0.4rem;
    border: 0.05rem solid #111;
    gap: 0.165rem;
}

.vehicleItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.265rem;
    padding-inline: 0.29rem;
    max-width: 5.5rem;
    min-width: 3.25rem;
    max-height: 3.5rem;
    font-size: 0.75rem;
    text-align: center;
    border-radius: 0.2rem;
    background-color: #e0e0e0;
    border: 0.05rem solid #111;
    cursor: pointer;
    transition: background-color 225ms ease, border 225ms ease;
}

.vehicleItem:hover {
    background-color: #599afc !important;
}

.vehicleItem.selectedMidItem {
    padding: 0.265rem;
    padding-inline: 0.29rem;
    border: 0.175rem solid #0c6cfc;
}

.vehicleItem.active {
    background-color: rgb(80, 250, 89);
}

.vehicleItem.recentlyActive {
    background-color: rgb(247, 201, 53);
}

.vehicleName {
    font-weight: bold;
}

.vehicleUsername {
    font-size: 0.785rem;
    font-weight: 500;
    line-height: normal;
    color: #555;
    text-wrap: wrap;
    overflow-wrap: anywhere;
}

.active {
    background-color: rgb(80, 250, 89);
}

.recentlyActive {
    background-color: rgb(247, 201, 53);
}

.verticalButton {
    display: flex;
    padding: 0.45rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    margin: 0.5rem 0rem;
    background-color: #0c6cfc;
    color: white;
    border-radius: 0.25rem;
    cursor: pointer;
}

.verticalButton span {
    writing-mode: vertical-lr;
    text-orientation: sideways;
    transform: rotate(180deg);
}

.verticalButton:hover {
    background-color: #0056b3;
}

.bottomSection {
    display: flex;
    flex-direction: column;
    padding: 0.25rem;
    margin-top: 0.25rem;
}


.Tab, .invTab {
    display: inline-block;
    padding: 0.1rem 0.225rem 0.125rem 0.225rem;
    color: black;
    margin-left: 0.15rem;
    margin-right: 0.15rem;
    flex: 1;
    flex-direction: row;
    font-size: 0.7rem;
    font-weight: 500;
    border-radius: 0.3rem;
    background-color: #f0f0f0;
    cursor: pointer;
}

.Tab {
    border-bottom-left-radius: 0rem;
    border-bottom-right-radius: 0rem;
}

.invTab {
    border-top-left-radius: 0rem;
    border-top-right-radius: 0rem;
}

.Tab:hover, .invTab:hover {
    background-color: #0c6cfc;
    color: white;
}

.TabMargins, .invTabMargins {
    margin-left: 0.4rem;
    margin-right: 0.4rem;
    margin-top: 0.4rem;
}

.bottomTabSection {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.settingText {
    margin-left: 0.25rem;
    font-size: 0.9rem;
}

.dropdown {
    margin: 0.25rem;
    padding: 0.15rem;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    color: black;
    background-color: #f0f0f0;
    font-weight: 600;
}

.mapstyle {
    flex: 1;
    flex-direction: row;
    aspect-ratio: 1;
    border-radius: 0.25rem;
    border: 0.1rem solid #0c80fc;
}

.bottomTabInfo {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    padding: 0.25rem;
}

.searchInput {
    margin: 0.2rem;
    padding: 0.15rem;
    border-radius: 0.25rem;
    color: black;
}

.actionButton {
    background-color: #e6a23c;
    color: #000;
    padding: 0.175rem;
    padding-inline: 0.2rem;
    border-radius: 0.2rem;
    font-size: 0.75rem;
    font-weight: 500;
}

.actionButton:hover {
    background-color: #cf8a2c;
}

.inactiveActionButton {
    background-color: #f1f1f1;
    color: #000;
    padding: 0.175rem;
    padding-inline: 0.2rem;
    border-radius: 0.2rem;
    font-size: 0.75rem;
    font-weight: 500;
}

.inactiveActionButton:hover {
    background-color: #f3ba69;
}

.column {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-evenly;
    align-self: flex-start;
    gap: 0.35rem;
    min-width: 0;
}

.column div {
    white-space: pre;
}

.window {
    display: flex;
    width: max-content;
    overflow: auto;
    min-width: 10.75rem;
    flex-direction: column;
    border-radius: 0.3125rem;
    border: 0.0625rem solid #111;
}

.windowHeader {
    display: flex;
    padding: 0.05rem;
    flex-direction: row;
    border-bottom: 0.0625rem solid #111;
    font-size: 0.825rem;
}


.windowHeader:last-child {
    padding: 0.25rem;
}

.windowTitle {
    font-size: 0.875rem;
    font-weight: 500;
    padding: 0.05rem;
    padding-inline: 0.125rem;
}

.windowContent {
    display: flex;
    padding: 0.3rem;
    width: max-content;
    overflow: auto;
}

.verticalSection {
    margin-left: 0.4375rem;
    align-self: flex-start;
    flex-direction: column;
    gap: 0.375rem;
}

.verticalButton {
    display: flex;
    font-size: 0.825rem;
    padding: 0.75rem 0.375rem;
    background-color: #d3d3d3;
    color: black;
    border-radius: 0.3125rem;
    cursor: pointer;
    writing-mode: vertical-lr;
    text-orientation: sideways;
    transform: rotate(180deg);
}

.verticalButton:hover {
    background-color: #b0b0b0;
}

.verticalButtonActive {
    background-color: #0c6cfc;
    color: white;
}

.costSection {
    display: flex;
    flex-direction: row;
    gap: 0.45rem;
    padding: 0.1rem;
    margin-top: -0.125rem;
    margin-bottom: 0.125rem;
    margin-right: 0.275rem;
}

.costItem {
    font-size: 0.6525rem;
    font-weight: 500;
    padding-inline: 0.225rem;
    padding-bottom: 0.07rem;
    padding-top:  0.07rem;
    border-radius: 0.1875rem;
    text-align: center;
    background-color: #d1d1d1;
}

.sectionTitle {
    font-size: 0.865rem;
    font-weight: 500;
}

.tableColumn {
    cursor: pointer;
}

.column-sorting {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    overflow: auto;
    flex-direction: column;
    position: relative;
}

.icon-container {
    display: inline-block;
    cursor: pointer;
    position: relative;
}

.menu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    border: 0.0625rem solid #ccc;
    z-index: 1000;
    width: 9.375rem;
}

.menu-section {
    padding: 0.5rem;
    border-bottom: 0.0625rem solid #eee;
}

.menu-title {
    font-weight: bold;
    margin-bottom: 0.3125rem;
}

.menu-item {
    padding: 0.3125rem 0;
    cursor: pointer;
}

.menu-item:hover {
    background-color: #f0f0f0;
}

.menu:last-child .menu-section {
    border-bottom: none;
}

.tableBlock {
    border: 0.0625rem solid black;
    padding: 0.1875rem;
}

.expand {
    flex: 1;
    flex-shrink: 1;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: auto;
    max-height: 30vh;
    min-width: 0;
}

.doubleExpanded {
    max-height: 60.25vh;
}

.mapContainer {
    width: 32rem;
    height: auto;
    aspect-ratio: 8 / 10;
    border: 0.125rem solid blue;
}

.mapPopup {
    font-size: calc(3.25px + 0.45rem);
}

.missionTitle {
    font-size: 1.1rem;
    margin-bottom: 0.15rem;
}

.missionItem {
    font-size: 0.8rem;
    margin-bottom: -0.125rem;
}

.radioItem {
    margin-top: -0.425rem;
    margin-bottom: -0.425rem;
    margin-left: -0.2125rem;
    font-size: 0.7rem;
}

.min11 {
    min-width: 11.25rem;
}

.min24 {
    min-width: 24rem;
}

.orderTable {
    overflow-x: auto;
}

.sticky {
    position: sticky;
    top: 0;
    right: 5;
    background-color: white;
    z-index: 2;
}





@media (max-width: 3072px) {
    html, body, #root {
        font-size: 15.25px;
    }

    .mapContainer {
        width: 30rem;
        aspect-ratio: 7.75 / 10;
    }
}

@media (max-width: 2560px) {
    html, body, #root {
        font-size: 13.5px;
    }

    .mapContainer {
        width: 25rem;
        aspect-ratio: 7.5 / 10;
    }
}

@media (max-width: 2304px) {
    html, body, #root {
        font-size: 12.75px;
    }

    .mapContainer {
        width: 22rem;
        aspect-ratio: 7.25 / 10;
    }
}

@media (max-width: 1920px) {
    html, body, #root {
        font-size: 11.75px;
    }

    .mapContainer {
        width: 20rem;
        aspect-ratio: 7 / 10;
    }
}

@media (max-width: 1600px) {
    html, body, #root {
        font-size: 10.75px;
    }

    .mapContainer {
        width: 18rem;
        aspect-ratio: 6.75 / 10;
    }

    .wmax {
        max-width: 78vmax;
    }
}

@media (max-width: 1270px) {
    html, body, #root {
        font-size: 10.25px;
    }

    .mapContainer {
        width: 15rem;
        aspect-ratio: 6.5 / 10;
    }

    .wmax {
        max-width: 74vmax;
    }
}