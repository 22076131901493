.general-table {
  border-collapse: separate;
  border-spacing: 0;
  table-layout: auto;
  font-size: calc(2.575px + 0.525rem);
  min-width: 100%;
}

.general-table th,
.general-table td {
  padding: 0.135rem;
  padding-bottom: 0.05rem;
  padding-top: 0.05rem;
  text-align: left;
  width: auto;
  max-width: 12.5rem;
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: table-cell;
}

.general-table th {
  border-right: 0.1rem solid rgb(60, 175, 241);
  border-bottom: 0.1rem solid rgb(60, 175, 241);
  border-top: 0.1rem solid rgb(60, 175, 241);
  white-space: nowrap;
}

.general-table td {
  border-right: 0.0625rem solid black;
  border-bottom: 0.0625rem solid black;
}

.general-table th:first-child,
.general-table td:first-child {
  border-left: 0.0625rem solid black;
}

.general-table tbody tr:last-child td {
  border-bottom: 0.0625rem solid black;
}

.general-table th:first-child {
  border-left: 0.0625rem solid rgb(60, 175, 241);
}

.general-table th:last-child {
  border-right: 0.0625rem solid rgb(60, 175, 241);
}

.general-table td:last-child {
  border-right: 0.0625rem solid black;
}

.general-table tbody tr {
  border-bottom: 0.0625rem solid black;
}

.general-table thead {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.general-table thead tr {
  border-bottom: 0.125rem solid black;
}

.general-table thead tr:first-child {
  cursor: pointer;
  font-size: 0.675rem;
}

.general-table thead tr:first-child th {
  border-bottom: none;
}

.general-table tbody tr:last-child {
  border-bottom: 0.0625rem solid black;
}

.general-table input {
  max-width: 0px;
  flex-wrap: wrap;
  min-height: 1.1rem;
  border-radius: 4px;
}

.selectedRow {
  background-color: rgb(197, 197, 197);
}