




.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
  
.modal-content {
    background-color: #fff;
    border-radius: 8px;
    max-width: 90%;
    width: 500px;
    max-height: 80vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
}
  
.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    border-bottom: 1px solid #222;
    box-shadow: 0 1.5px 2.5px rgba(43, 43, 43, 0.42);
}
  
.modal-body {
    flex: 1;
    padding: 1rem;
    display: flex;
    flex-direction: column;
}
  
.modal-textarea {
    flex: 1;
    width: 100%;
    resize: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    font-size: 16px;
    box-sizing: border-box;
}
  
.modal-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 12px;
    border-top: 1px solid #222;
}
  
.modalButton {
    border-radius: 8px;
    padding: 8px 20px;
    margin-left: 0.65rem;
    cursor: pointer;
    font-size: 17px;
}
  
.modal-close-button {
    background: none;
    border: none;
    font-size: 2.25rem;
    padding: 0.75rem;
    cursor: pointer;
}
  